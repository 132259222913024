import { ReactNode } from 'react';
import { VariantProps } from 'tailwind-variants';

import { textClasses } from './styles';

type TextVariants = VariantProps<typeof textClasses>;

interface TextProps
  extends React.HTMLAttributes<typeof HTMLSpanElement | HTMLParagraphElement>,
    TextVariants {
  as?: React.ElementType; // HTMLSpanElement | HTMLParagraphElement;
  className?: string;
  children: ReactNode;
  truncate?: boolean;
}

const DEFAULT_TEXT_AS = 'span';

export function Text({
  as: TextComponent = DEFAULT_TEXT_AS,
  size,
  className,
  children,
  truncate = false,
  ...props
}: TextProps) {
  return (
    <TextComponent
      className={textClasses({
        size,
        className,
        truncate,
      })}
      {...props}
    >
      {children}
    </TextComponent>
  );
}
