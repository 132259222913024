import { forwardRef } from 'react';

import { inputFieldClasses } from './styles';

export interface IInputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const InputField = forwardRef<HTMLInputElement, IInputFieldProps>(
  ({ className, type, ...props }, ref) => (
    <input
      type={type}
      className={inputFieldClasses({
        className,
        type: type as 'checkbox' | 'radio',
      })}
      ref={ref}
      {...props}
    />
  ),
);

InputField.displayName = 'Input';

export { InputField };
