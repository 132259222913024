import { tv } from 'tailwind-variants';

export const inputLabelClasses = tv({
  base: 'text-sm text-blueGray-700 flex gap-2 capitalize items-center mb-1',
  variants: {
    required: {
      true: 'before:mr-0.5 before:text-sm before:content-["*"]',
    },
  },
});
