import { z } from 'zod';

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-_~^+=`|{}[\]:;"'<>,.?/])[A-Za-z\d@$!%*?&-_~^+=`|{}[\]:;"'<>,.?/]{8,}$/;

export const signinZod = z.object({
  email: z.string().email().min(1, { message: 'Email is required' }),
  password: z.string().min(1, { message: 'Password is required' }),
});

export const signUpZodSchema = z.object({
  email: z.string().email().min(1, 'Email is required'),
  name: z.string().min(1, 'Name is required'),
  password: z
    .string()
    .regex(
      passwordRegex,
      'Password must be at least 8 characters and include one uppercase letter, one lowercase letter, one number, and one special character',
    ),
});

export const recoverZodSchema = z.object({
  email: z.string().email().min(1, { message: ' Email is required' }),
});

export const newPasswordZodSchema = z
  .object({
    password: z
      .string()
      .regex(
        passwordRegex,
        'Password must be at least 8 characters and include one uppercase letter, one lowercase letter, one number, and one special character',
      ),
    confirmPassword: z.string().min(1, ' Confirm Password is required'),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: 'Passwords must match',
    path: ['confirmPassword'],
  });
