import { iconClasses } from '../styles';

export function IconLoader({ className, ...props }: IconProps) {
  return (
    <svg
      className={iconClasses({ className })}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path d='M12 6V3' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M16.5 4.20996L15 6.80996'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.19 9L19.79 7.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M21 12H18' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 12H3' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M4.21001 7.5L6.81001 9'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 4.20996L9 6.80996'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12 21V18' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M9 17.1904L7.5 19.7904'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.81001 15L4.21001 16.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.19 15L19.79 16.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 17.1904L16.5 19.7904'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
