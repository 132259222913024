import { forwardRef } from 'react';
import { VariantProps } from 'tailwind-variants';

import { inputLabelClasses } from './styles';

type TInputLabelProps = React.LabelHTMLAttributes<HTMLLabelElement> &
  VariantProps<typeof inputLabelClasses>;

const InputLabel = forwardRef<HTMLLabelElement, TInputLabelProps>(
  ({ children, className, required = false, ...props }, ref) => (
    <label
      className={inputLabelClasses({ required, className })}
      ref={ref}
      {...props}
    >
      {children}
    </label>
  ),
);

InputLabel.displayName = 'Label';

export { InputLabel };
