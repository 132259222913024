import { tv } from 'tailwind-variants';

export const buttonClasses = tv({
  base: 'inline-flex items-center justify-center gap-x-1 rounded-md border font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-purpleBlue-600 focus:ring-offset-2 disabled:border-transparent disabled:bg-gray-300 disabled:text-gray-500',
  variants: {
    color: {
      red: 'border border-red-200 bg-red-50 text-sm font-semibold text-red-600 hover:bg-red-100 focus:ring-red-600',
      purple:
        'border border-purpleBlue-200 bg-purpleBlue-50 text-sm font-semibold text-purpleBlue-600 hover:bg-purpleBlue-100',
      primary:
        'border-transparent bg-purpleBlue-600 text-white hover:bg-purpleBlue-500',
      'primary-outline':
        'border-purpleBlue-600 bg-white text-purpleBlue-600 hover:bg-blueGray-100',
      white:
        'border-blueGray-100 bg-white text-blueGray-700 shadow-none hover:border-blueGray-200 hover:bg-blueGray-50 focus:border-blueGray-100',
      navy: 'text-semibold border-0 bg-navyBlue-50 text-purpleBlue-600 shadow-sm hover:bg-navyBlue-100',
      green: 'bg-green-400 text-white shadow-sm focus:ring-green-400',
      gray: 'border border-blueGray-200 bg-blueGray-50 text-sm font-semibold text-blueGray-600 hover:bg-blueGray-100 focus:ring-blueGray-600',
      transparent:
        'border-none bg-inherit font-bold text-purpleBlue-600 shadow-none',
    },

    appearence: {
      dashed: '!border-dashed !border-2',
    },

    size: {
      xxs: 'text-xxs h-6 p-1',
      xs: 'h-7 px-2 py-1 text-xs',
      sm: 'h-8 px-3 py-2 text-sm leading-4',
      base: 'h-10 px-4 py-2 text-sm',
      lg: 'h-11 px-4 py-2 text-base',
      xl: 'h-12 px-6 py-3 text-base',
    },
  },
  compoundVariants: [
    {
      color: 'gray',
      appearence: 'dashed',
      className: 'border-blueGray-200 focus:ring-0 text-blueGray-600 ',
    },
  ],
  defaultVariants: {
    color: 'primary',
    size: 'base',
  },
});

export const loadingClasses = tv({
  base: 'animate-spin stroke-2',
  variants: {
    color: {
      red: 'stroke-red-600',
      purple: 'stroke-purpleBlue-600',
      primary: 'stroke-white',
      'primary-outline': 'stroke-purpleBlue-600',
      white: 'stroke-purpleBlue-600',
      navy: 'stroke-purpleBlue-600',
      green: 'stroke-white',
      gray: 'stroke-blueGray-600',
      transparent: 'stroke-purpleBlue-600',
      dashed: 'stroke-gray-600',
    },
    size: {
      xxs: 'h-4',
      xs: 'h-4',
      sm: 'h-4',
      base: 'h-5',
      'base-1': 'h-5',
      lg: 'h-6',
      xl: 'h-8',
    },
  },
  defaultVariants: {
    color: 'primary',
    size: 'base',
  },
});
export const innerClasses = tv({
  base: 'inline-flex items-center gap-x-1 justify-center',
  variants: {
    hide: {
      true: 'hidden',
    },
  },
});
export const loadingContainerClasses = tv({
  base: 'hidden',
  variants: {
    loading: {
      true: 'flex justify-center items-center',
      false: 'hidden',
    },
  },
});
