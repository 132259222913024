import { iconClasses } from '../styles';

function IconSearch({ className, ...props }: IconProps) {
  return (
    <svg
      className={iconClasses({ className })}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14.1931 5.58187C16.5249 7.91369 16.5249 11.6943 14.1931 14.0261C11.8613 16.358 8.08065 16.358 5.74883 14.0261C3.41701 11.6943 3.41701 7.91369 5.74883 5.58187C8.08065 3.25005 11.8613 3.25005 14.1931 5.58187'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.15 14.0596L20 19.9896'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export { IconSearch };
