import { tv } from 'tailwind-variants';

export const textClasses = tv({
  base: 'font-base text-blueGray-700',
  variants: {
    truncate: {
      true: 'w-[100px] truncate',
    },
    size: {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-base',
      lg: 'text-lg',
      xl: 'text-xl',
      '2xl': 'text-2xl',
      '4xl': 'text-4xl',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});
