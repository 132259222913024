import * as PopoverPrimitive from '@radix-ui/react-popover';

import { PopoverContent } from './components/PopoverContent';

const Popover = {
  Root: PopoverPrimitive.Root,
  Content: PopoverContent,
  Trigger: PopoverPrimitive.Trigger,
  Close: PopoverPrimitive.Close,
};

export default Popover;
