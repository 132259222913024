'use client';

import * as PopoverPrimitive from '@radix-ui/react-popover';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { popoverClasses } from './styles';

const PopoverContent = forwardRef<
  ElementRef<typeof PopoverPrimitive.Content>,
  ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = 'center', sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Content
    ref={ref}
    align={align}
    sideOffset={sideOffset}
    onOpenAutoFocus={e => e.preventDefault()}
    className={popoverClasses({ className })}
    {...props}
  />
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { PopoverContent };
