import { Text } from '@/app/components/Typography';

export function MessageAuthGuest({
  message,
  callToAction,
  onClick,
  form,
}: IMessageAuthOrganizationProps) {
  const handleClick = () => {
    onClick(form);
  };

  return (
    <Text size='sm' className='mt-2 w-full min-w-full px-10 text-center'>
      {message}{' '}
      <span
        onClick={handleClick}
        role='button'
        className='font-semibold text-purpleBlue-600'
      >
        {callToAction}
      </span>
    </Text>
  );
}
