import { forwardRef } from 'react';

import { IconSearch } from '@/icons/SearchIcon';

import { InputField } from '../InputField';
import { inputFieldClasses } from '../InputField/styles';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const InputSearch = forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => (
    <div className='relative'>
      <IconSearch className='absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2' />

      <InputField
        className={`${inputFieldClasses({
          className,
        })} pl-8`}
        ref={ref}
        {...props}
      />
    </div>
  ),
);

InputSearch.displayName = 'InputSearch';

export { InputSearch };
