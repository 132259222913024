import { tv } from 'tailwind-variants';

export const inputFieldClasses = tv({
  base: [
    'h-10 w-full p-2',
    'bg-gray-100 hover:bg-white focus:bg-white disabled:hover:!bg-gray-100 read-only:hover:!bg-gray-100 read-only:focus:!bg-gray-100',
    'rounded-md border border-transparent hover:border-purpleBlue-600 focus:border-purpleBlue-600 disabled:!border-gray-200 disabled:focus:!border-gray-200 read-only:!border-gray-200 focus:read-only:!border-gray-200 read-only:focus:!ring-0 read-only:hover:!border-gray-200',
    'focus:ring-purpleBlue-600 active:ring-purpleBlue-600',
    'text-sm text-blueGray-800 placeholder-blueGray-300 disabled:!text-blueGray-300 read-only:!text-blueGray-300',
    'disabled:!cursor-not-allowed read-only:!cursor-default',
  ],
  variants: {
    type: {
      radio: [
        'h-5 w-5 rounded-full',
        'bg-white checked:!bg-purpleBlue-700 active:checked:!bg-purpleBlue-600 focus:checked:!bg-purpleBlue-600 hover:checked:!bg-purpleBlue-600',
        'checked:ring-purpleBlue-600 checked:focus:ring-purpleBlue-600 active:ring-2 focus:checked:ring-purpleBlue-600 focus:checked:!ring-2 focus:active:ring-offset-2 active:ring-offset-2',
        'text-purpleBlue-600 hover:!text-purpleBlue-500',
        'disabled:checked:!bg-purpleBlue-600',
        'read-only:checked:!bg-purpleBlue-600 read-only:focus:checked:!bg-purpleBlue-600 read-only:bg-gray-100 read-only:checked:hover:!bg-purpleBlue-600',
      ],
      checkbox: [
        'h-5 w-5 text-purpleBlue-600 hover:!text-purpleBlue-500',
        'checked:!bg-purpleBlue-600 checked:ring-purpleBlue-600',
        'active:ring-2 active:ring-offset-2 active:checked:!bg-purpleBlue-600',
        'disabled:checked:!bg-purpleBlue-600 checked:hover:!bg-purpleBlue-600 read-only:checked:hover:!bg-purpleBlue-600 read-only:checked:!bg-purpleBlue-600 read-only:focus:checked:!bg-purpleBlue-600',
      ],
    },
  },
});
