import { forwardRef } from 'react';

import { classes } from './styles';

export const InputRoot = forwardRef<HTMLDivElement, IInputProps>(
  ({ children, className, ...props }, ref) => (
    <div ref={ref} className={classes({ className })} {...props}>
      {children}
    </div>
  ),
);
