import { THeadingProps } from '../types';

import { headerClasses } from './styles';

const DEFAULT_HEADING_AS = 'h2';

export function Heading({
  as: HeadingComponent = DEFAULT_HEADING_AS,
  size,
  className,
  children,
}: THeadingProps) {
  return (
    <HeadingComponent
      className={headerClasses({
        size,
        className,
      })}
    >
      {children}
    </HeadingComponent>
  );
}
