import { tv } from 'tailwind-variants';

export const headerClasses = tv({
  base: 'font-bold text-blueGray-700',
  variants: {
    size: {
      xxs: 'text-xxs',
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-base',
      lg: 'text-lg',
      xl: 'text-xl',
      '2xl': 'text-2xl',
      '4xl': 'text-4xl',
    },
  },
  defaultVariants: {
    size: 'xl',
  },
});
