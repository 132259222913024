'use client';

import { MouseEvent, forwardRef, useCallback, useMemo, useState } from 'react';

import { IconLoader } from '@/icons/IconLoader';

import {
  buttonClasses,
  innerClasses,
  loadingClasses,
  loadingContainerClasses,
} from './styles';
import { TButtonProps } from './types';

const Button = forwardRef<HTMLButtonElement, TButtonProps>(
  (
    {
      className,
      color,
      size,
      hideChildrenOnLoading,
      children,
      appearence,
      onClick,
      defaultLoading,
      hideLoading,
      type = 'button',
      loadingClassName,
      ...props
    },
    ref,
  ) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = useCallback(
      async (event: MouseEvent<HTMLButtonElement>) => {
        if (!onClick || isLoading || defaultLoading) return;

        if (!hideLoading || !defaultLoading) {
          setIsLoading(true);
        }

        await onClick(event);
        setIsLoading(false);
      },
      [defaultLoading, hideLoading, isLoading, onClick],
    );

    const showLoading = useMemo(
      () => isLoading || defaultLoading,
      [defaultLoading, isLoading],
    );

    return (
      <button
        className={buttonClasses({ color, size, className, appearence })}
        ref={ref}
        onClick={handleClick}
        type={type}
        {...props}
      >
        <div
          className={loadingContainerClasses({
            loading: showLoading && !hideLoading,
          })}
        >
          <IconLoader
            className={loadingClasses({
              color,
              size,
              className: loadingClassName,
            })}
          />
        </div>
        <span
          className={innerClasses({
            hide: hideChildrenOnLoading && showLoading,
          })}
        >
          {children}
        </span>
      </button>
    );
  },
);

Button.displayName = 'Button';

export { Button };
